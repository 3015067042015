import Swal from "sweetalert2";
import "./Contact.css";

function Contact() {
  // Form gönderildiğinde çalışacak işlev
  const handleSubmit = (e) => {
    e.preventDefault();

    // SweetAlert ile uyarı göster
    Swal.fire({
      title: "Mesajınız Tarafımıza Ulaştı!",
      text: "En kısa sürede dönüş yapılacaktır.",
      icon: "success",
      confirmButtonText: "Tamam",
      timer: 2000,
      showConfirmButton: false,
    }).then((result) => {
      window.location.reload();
    });
  };

  return (
    <div>
      <section id="contact">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <h3 className="section-header">Bize Ulaşın</h3>
        <div className="contact-wrapper">
          <form
            id="contact-form"
            className="form-horizontal"
            role="form"
            onSubmit={handleSubmit}
          >
            <div className="form-group">
              <div className="col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Ad Soyad Giriniz"
                  name="name"
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-12">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email Giriniz"
                  name="email"
                  required
                />
              </div>
            </div>
            <textarea
              className="form-control mesaj"
              rows={16}
              placeholder="Mesajınızı Giriniz"
              name="message"
              required
            />
            <button
              className="btn send-button"
              id="submit"
              type="submit"
              value="SEND"
            >
              GÖNDER &nbsp;
              <i className="fa fa-paper-plane" />
            </button>
          </form>

          <div className="direct-contact-container">
            <ul className="contact-list">
              <li className="list-item">
                <i className="fa fa-phone fa-xl">
                  <span className="contact-text phone">
                    <a href="tel:902127316550" title="Telefon Et">
                      (212) 731 65 50
                    </a>
                  </span>
                </i>
              </li>
              <br />
              <li className="list-item">
                &nbsp;
                <i className="fa fa-mobile fa-xl">
                  <span className="contact-text phone">
                    <a href="tel:905356360094" title="Telefon Et">
                      (535) 636 00 94
                    </a>
                  </span>
                </i>
              </li>
              <br />
              <li className="list-item">
                <i className="fa fa-envelope fa-xl">
                  <span className="contact-text gmail">
                    <a href="mailto:klasemlak@outlook.com.tr" title="Email">
                      klasemlak@outlook.com.tr
                    </a>
                  </span>
                </i>
              </li>
              <br />
              <li className="list-item">
                <i className="fa fa-map-marker fa-xl">
                  <a
                    className="contact-text place"
                    href="https://maps.app.goo.gl/Nfv87aMe2tQfKAX87"
                  >
                    Adres : Ortaköy Mahallesi Hümayun Caddesi No:59
                    Silivri/İstanbul <br />
                    <br />
                    <i className="fa fa-map fa-lg"></i> Adres 2: Selimpaşa
                    Mahallesi Ortaköy Caddesi No:1 Silivri / İstanbul
                  </a>
                </i>
              </li>
            </ul>
            <hr />
            <ul className="social-media-list">
              <li>
                <a
                  href="https://www.facebook.com/Klasemlakgayrimenkul"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-icon"
                  style={{ width: "60px" }}
                >
                  <i style={{ fontSize: "37px" }} className="fa fa-facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/klasemlakgayrimenkul/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-icon"
                >
                  <i className="fa fa-instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/klas-emlak-gayri%CC%87menkul/about/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-icon"
                >
                  <i className="fa fa-linkedin" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCNrhyOxsIpeY4hDnVuS7aGg"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="contact-icon"
                >
                  <i className="fa fa-youtube" />
                </a>
              </li>
            </ul>
            <hr />
            <div className="copyright">© Tüm bilgiler KVKK kapsamındadır. </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
