import React, { useEffect, useState } from "react";
import "./Press.css";
import foto from "./95185.jpg";
import axios from "axios";
import { Link } from "react-router-dom";

function Press() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [presses, setPresses] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}press`)
      .then((res) => {
        setPresses(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div id="press" className="press-page">
      <div className="press-page">
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <h3 className="baslik">Medyada Klas Emlak</h3>
        </div>

        <div className="containerx">
          {presses.map((press) => (
            <>
              <ul className="news-cards">
                <li className="news-cards__item entry-news-card">
                  <div className="entry-news-card__frame">
                    <div className="entry-news-card__picture">
                      <a href>
                        <img
                          src={press.imageUrl}
                          alt="klas emlak silivri hür haber"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="entry-news-card__content">
                    <div className="entry-news-card__category">
                      <Link
                        target="_blank"
                        to={press.url}
                        className="entry-news-card__category-link"
                      >
                        {press.url}
                      </Link>
                    </div>
                    <h2 className="entry-news-card__title">
                      <h4>{press.title}</h4>
                    </h2>
                    <ul className="entry-news-card__meta">
                      <li className="entry-news-card__meta-item">
                        <span className="visually-hidden"></span>
                        <time
                          className="entry-news-card__date"
                          dateTime="2019-01-07T18:18"
                        ></time>
                      </li>
                      <li className="entry-news-card__meta-item">
                        <span className="visually-hidden">Автор:</span>
                        <span className="entry-news-card__author"></span>
                      </li>
                    </ul>
                    <div className="entry-news-card__text">
                      <p>{press.description}</p>
                    </div>
                  </div>
                </li>
              </ul>
              <br />
              <br />
            </>
          ))}
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default Press;
