import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCpkKe1jflB5SJPBhL-X1VTMiu4r0tDGWg",
  authDomain: "klasemlak-763ae.firebaseapp.com",
  projectId: "klasemlak-763ae",
  storageBucket: "klasemlak-763ae.appspot.com",
  messagingSenderId: "628232106411",
  appId: "1:628232106411:web:d6ba743f66cee84ae222ee",
  measurementId: "G-SD41K2ZQBE",
};

const firebaseApp = initializeApp(firebaseConfig);
const storage = getStorage(firebaseApp);

export { storage };
