import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div id="foot">
      <footer className="footer">
        <div className="container row">
          <div className="footer-col">
            <h4>KLAS EMLAK</h4>
            <ul>
              <li>
                <Link to="/about">Hakkımızda</Link>
              </li>
              <li>
                <Link to="/ref">Referanslar</Link>
              </li>
              <li>
                <Link to="/gizlilik">Gizlilik Politikası</Link>
              </li>
              <li>
                <Link to="/press">Basında Biz</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Kurumsal</h4>
            <ul>
              <li>
                <Link to="/faq">Sıkça Sorulan Sorular</Link>
              </li>
              <li>
                <Link to="/listing">İlanlarımız</Link>
              </li>
              <li>
                <Link to="/team">Yönetim Kurulu</Link>
              </li>
              <li>
                <Link to="/inform">Gayrimenkul Rehberi</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Bize Ulaşın</h4>
            <ul>
              <li>
                <i className="fa-solid fa-envelope" />
                <a id="mailt" href="mailto:klasemlak@outlook.com.tr">
                  klasemlak@outlook.com.tr
                </a>
              </li>
              <li>
                <i className="fa-solid fa-phone" />
                <a href="tel:+902127316550"> &nbsp;02127316550</a>
              </li>
              <li>
                <i className="fa-solid fa-map-marker-alt fa-lg " />
                <a href="https://maps.app.goo.gl/Nfv87aMe2tQfKAX87">
                  Selimpaşa Merkez, Ortaköy Cd. No:1 Silivri İstanbul
                </a>
              </li>
              <li>
                <i className="fas fa-comment-alt" /> &nbsp;
                <Link to="/contact">İletişim Formu</Link>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Bizi Takip Edin</h4>
            <div className="social-links">
              <a href="https://www.facebook.com/Klasemlakgayrimenkul">
                <i className="fa-brands fa-facebook-f" />
              </a>

              <a href="https://www.instagram.com/klasemlakgayrimenkul/">
                <i className="fa-brands fa-instagram" />
              </a>
              <a href="https://www.linkedin.com/company/klas-emlak-gayri%CC%87menkul/about/">
                <i className="fa-brands fa-linkedin-in" />
              </a>

              <a href="https://www.youtube.com/channel/UCNrhyOxsIpeY4hDnVuS7aGg">
                <i className="fa-brands fa-youtube" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
