import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Main from "./components/Main/Main";
import Contact from "./pages/Contact/Contact";
import Press from "./pages/Press/Press";
import Ref from "./pages/Referances/Ref";
import Faq from "./components/Faq/Faq";
import About from "./components/About/About";
import Team from "./components/Team/Team";
import Listing from "./components/Listing/Listing";
import Icons from "./components/icon/Icons";
import Details from "./components/Details/Details";
import Navbar1 from "./components/Navbar/Navbar1";
import Inform from "./components/Inform/Inform";
import Informiki from "./components/Informiki/Informiki";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import RefDetails from "./pages/Referances/RefDetails";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar1 />
      <Icons />
      <Routes>
        <Route path="/" element={<Main />} exact />
        <Route path="/contact" element={<Contact />} exact />
        <Route path="/press" element={<Press />} exact />
        <Route path="/ref" element={<Ref />} exact />
        <Route path="/faq" element={<Faq />} exact />
        <Route path="/about" element={<About />} exact />
        <Route path="/team" element={<Team />} exact />
        <Route path="/listing" element={<Listing />} exact />
        <Route path="/listing/:param" element={<Listing />} exact />
        <Route path="/details/:id" element={<Details />} exact />
        <Route path="/inform" element={<Inform />} exact />
        <Route path="/gizlilik" element={<Informiki />} exact />
        <Route path="/refdetails/:id" element={<RefDetails />} exact />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
