import React, { useEffect } from 'react';
import './Handwrite.css';

const Handwrite = () => {
  useEffect(() => {
    const text = document.querySelector('.handwriting-text');

    
    const totalLength = 'Yaşam Mimarı\u0131'.length ;
    let length = 0;

    function drawText() {
      length += 1;
      text.innerHTML = ('Yaşam Mimar\u0131').substr(0, length);

      if (length < totalLength) {
        setTimeout(drawText, 100);
      }
    }

    drawText();
  }, []);

  return (
    <div className="handwriting-container">
      <div className="handwriting-text" />
    </div>
  );
};

export default Handwrite;
