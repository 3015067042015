import React from "react";
import "../Team/Team.css";
import "./Inform.css";

function Inform() {
  return (
    <div className="team">
      <div className="teamwrapper">
        <section>
          <div className="row titleContainer">
            <h1>
              <b className="title">GAYRİMENKUL REHBERİ</b>
            </h1>
          </div>
          <div className="row" style={{ textAlign: "left" }}>
            <div className="column2" style={{ margin: "0 auto" }}>
              <div className="card2">
                <h2>Emlak Bilgisi</h2>
                <ul>
                  <li>
                    Ev, işyeri, arsa, arazi, bağ ve bahçe gibi vasıflara haiz,
                    alım satım ve kiralama işlemlerine konu olabilecek taşınmaz
                    mallara emlak denir.
                  </li>
                </ul>
                <br />
                <br />

                <h2>
                  Gayrimenkul Piyasasında Dikkat Edilecek Satır Başlıkları
                </h2>
                <ul>
                  <li>Tapu ve Kadastro</li>
                  <li>Belediye</li>
                  <li>Harç ve Vergi</li>
                  <li>Kat Mülkiyeti ve Kat İttifakı</li>
                  <li>Genel Hukuk</li>
                  <li>Kamulaştırma</li>
                </ul>
                <br />
                <br />

                <h2>
                  Konut ve İşyeri Alırken Dikkat Edilmesi Gereken Hususlar
                </h2>
                <ul>
                  <li>Konut veya işyerinin arsa payına,</li>
                  <li>
                    Kat ittifakının kurulması ve daha sonra kat mülkiyetine
                    geçilmesi,
                  </li>
                  <li>
                    Tapu Müdürlüğüne gidilip satışa herhangi bir engel olup
                    olmadığının kontrol edilmesi,
                  </li>
                  <li>
                    Konutun veya işyerinin değerinin belirlenmesinde kira
                    amortismanında bakılması gerekir.
                  </li>
                </ul>
                <br />
                <br />

                <h2>Arsa Alırken Dikkat Edilmesi Gereken Hususlar</h2>
                <ul>
                  <li>
                    Arsanın etrafında gerekli altyapılarının (elektrik, su,
                    doğalgaz vb.) varlığı,
                  </li>
                  <li>Arsanın yola cephesi ve inşaata uygunluğu,</li>
                  <li>
                    Arsanın hangi Belediyeye bağlıysa imar durumunun kontrol
                    edilmesi,
                  </li>
                  <li>
                    Arsa üzerinde herhangi bir şerh, ipotek veya haciz olmaması.
                  </li>
                </ul>
                <br />
                <br />

                <h2>
                  Arazi ve Bağ Bahçe Alırken Dikkat Edilmesi Gereken Hususlar
                </h2>
                <ul>
                  <li>Kadastrol yola cephesi olup olmadığı,</li>
                  <li>Arazinin toprak bakımından elverişli olması,</li>
                  <li>Arazinin imar alanına yakınlığına,</li>
                  <li>Altyapıya yakın olmasına.</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Inform;
