import React from "react";
import "./icon.css";

const Icons = () => {
  return (
    <>
      <div className="whats-float">
        <a href="https://wa.me/905356360094" target="_blank">
          <i className="fa fa-whatsapp" />
          <span>
            WhatsApp
            <br />
            <small>535 636 00 94</small>
          </span>
        </a>
      </div>
      <div className="sahibinden-float">
        <a href="https://klasemlakgayrimenkul.sahibinden.com/" target="_blank">
          <i class="fa-brands fa-stripe-s"></i>
          <span>Sahibinden.com</span>
        </a>
      </div>
    </>
  );
};

export default Icons;
