import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Details.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { useLocation } from "react-router";
import axios from "axios";
import Moment from "moment";

const Details = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [props, setProps] = useState({});
  const [images, setImages] = useState([]);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const getPropsById = async () => {
      try {
        const res = await axios.get(`${API_URL}prop/${id}`);
        setProps(res.data);
        setImages(res.data.imageUrls);
      } catch (error) {
        console.log(error);
      }
    };

    getPropsById();
  }, [id]);

  return (
    <div id="detail">
      <div className="container container-detail ">
        <div className="details d-flex">
          <figcaption className="detail-top-title">{props.title}</figcaption>
          {/* <span>
            {props.price !== undefined ? props.price.toLocaleString() : ""} ₺
          </span> */}
        </div>
        <div id="slider">
          <div className="row">
            <div className="slider col-md-6">
              <>
                <Swiper
                  style={{ "--swiper-navigation-color": "#fff" }}
                  spaceBetween={10}
                  navigation={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  thumbs={{
                    swiper:
                      thumbsSwiper && !thumbsSwiper.destroyed
                        ? thumbsSwiper
                        : null,
                  }}
                  className="product-images-slider2"
                >
                  {images && (
                    <>
                      {images.map((image, index) => (
                        <SwiperSlide key={index}>
                          <img
                            src={image}
                            alt={`Slider Image ${image.value}`}
                          />
                        </SwiperSlide>
                      ))}
                    </>
                  )}
                </Swiper>
                <Swiper
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  onSwiper={setThumbsSwiper}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="product-images-slider"
                >
                  {images && (
                    <>
                      {images.map((image, index) => (
                        <SwiperSlide key={index}>
                          <img
                            src={image}
                            alt={`Slider Image ${image.value}`}
                          />
                        </SwiperSlide>
                      ))}
                    </>
                  )}
                </Swiper>
              </>
            </div>
            <div className="col-md-6 info-ilan">
              <p className="descripton-title">İlan Bilgileri</p>
              <div className="row info">
                <div>
                  {props.adNumber && (
                    <div className="info-title">
                      <div className="info-title-detail">İlan Numarası</div>
                      <div className="info-title-detail"> {props.adNumber}</div>
                    </div>
                  )}
                  <div className="info-title">
                    <div className="info-title-detail">İlçe</div>
                    <div className="info-title-detail">{props.ilceCountry}</div>
                  </div>
                  <div className="info-title">
                    <div className="info-title-detail">
                      İlan Güncelleme Tarihi
                    </div>
                    <div className="info-title-detail">
                      {Moment(props.updatedAt).format("DD-MM-YYYY")}
                    </div>
                  </div>
                  <div className="info-title">
                    <div className="info-title-detail">Kategorisi</div>
                    <div className="info-title-detail">{props.category}</div>
                  </div>
                  <div className="info-title">
                    <div className="info-title-detail">Net Metrekare</div>
                    <div className="info-title-detail">{props.msquare} M2</div>
                  </div>
                  {props.category === "Konut" ? (
                    <div className="info-title">
                      <div className="info-title-detail">Oda Sayısı</div>
                      <div className="info-title-detail">
                        {props.numberofRoom}
                      </div>
                    </div>
                  ) : null}
                  <div className="info-title">
                    <div className="info-title-detail">
                      İlan Oluşturulma Tarihi
                    </div>
                    <div className="info-title-detail">
                      {Moment(props.createdAt).format("DD-MM-YYYY")}
                    </div>
                  </div>

                  <div className="info-title">
                    <div className="info-title-detail">Tipi</div>
                    <div className="info-title-detail">{props.type}</div>
                  </div>

                  {props.msquarePrice > 0 && (
                    <div className="info-title">
                      <div className="info-title-detail">Metrekare Fiyatı</div>
                      <div className="info-title-detail">
                        {props.msquarePrice}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="place-detail">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="detail-place">
                  <div style={{ width: "100%" }}>
                    <p className="descripton-title loc">İlan Konumu</p>
                    <div className="location">
                      <i class="fa-solid fa-location-dot"></i>
                      <p>İstanbul - Silivri</p>
                    </div>
                    <div>
                      <iframe
                        width="100%"
                        height="200"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=Selimpa%C5%9Fa%20Merkez,%20Ortak%C3%B6y%20Cd.%20No:1%20Silivri%20%C4%B0stanbul+(klas%20emlak)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                      >
                        <a href="https://www.maps.ie/population/">
                          Population calculator map
                        </a>
                      </iframe>
                    </div>
                  </div>

                  <p className="descripton-title explanation">
                    İlan Açıklaması
                  </p>
                  <div className="description">{props.description}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
