// ScrollToTop.jsx
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Sayfa değişikliğinde en üst kısmına kaydır
    scroll.scrollToTop({
      duration: 100, // Animasyon süresi (milisaniye cinsinden)
    });
  }, [pathname]);

  return null; // Bu bileşen hiçbir şey render etmeyecek
}

export default ScrollToTop;
