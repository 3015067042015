import React from "react";
import "./About.css";
import aboutjpg from "./about.jpeg";
import about2 from "./about2.jpg";
import about3 from "./about3.JPG";

function About() {
  return (
    <div className="about">
      <section
        className="py-3 py-md-5 py-xl-8"
        style={{ width: "85%", margin: "0 auto" }}
      >
        <div className="container-fluid mx-md-auto top-container">
          <div className="row gy-md-4 gy-lg-0 align-items-lg-center ">
            <div className="col-12 col-lg-8 col-xl-6 p-0">
              <img
                className="img-fluid"
                loading="lazy"
                src={aboutjpg}
                alt="hakkımızda"
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-6">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-11">
                  <h6 className="h1 mb-3">Biz Kimiz?</h6>
                  <p6 className="lead fs-5 text-secondary mb-3">
                    Klas Emlak olarak, 1992 yılından itibaren gayrimenkul
                    sektöründe elde ettiğimiz tecrübeyle, kaliteye olan
                    bağlılığımızı müşterilerimizin ihtiyaçları karşısında insan
                    odaklı çözme becerimizle gayrimenkul varlıklarına
                    odaklanıyoruz. Klas Emlak olarak, gayrimenkul yatırımında
                    doğru projeler üreterek güven esaslı hizmet veriyoruz.
                  </p6>
                  <br />
                  <br />
                  <br />
                  <div className="row gy-4 gy-md-0 gx-xxl-5X mb-4">
                    <div className="col-12 col-md-6">
                      <div className="d-flex about-flex">
                        <div className="me-4 text-primary">
                          <i
                            class="fa-solid fa-house-circle-check fa-2xl"
                            style={{ color: "#50A596" }}
                          ></i>
                        </div>
                        <div className="icon-container">
                          <h6 className="mb-3">
                            Size En Uygun Olanı Seçiyoruz
                          </h6>
                          <p6 className="text-secondary mb-0">
                            Deneyimli ve uzman kadromuzla, sizin için en uygun
                            olan gayrimenkulü öneriyoruz.
                          </p6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="d-flex about-flex">
                        <div className="me-4 text-primary">
                          <i
                            class="fa-solid fa-users fa-2x"
                            style={{ color: "#50A596" }}
                          ></i>
                        </div>
                        <div className="icon-container">
                          <h6 className="mb-3">Her Zaman Yanınızdayız</h6>
                          <p6 className="text-secondary mb-0">
                            Sorularınız ve istekleriniz için her zaman
                            erişilebilir ve size destek olmak için ekibimizle
                            hazırız.
                          </p6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="py-3 py-md-5 py-xl-8"
        style={{ width: "85%", margin: "0 auto" }}
      >
        <div className="container-fluid mx-md-auto">
          <div className="row gy-md-4 gy-lg-0 align-items-lg-center ">
            <div className="col-12 col-lg-8 col-xl-6 p-0">
              <img
                className="img-fluid"
                loading="lazy"
                src={about2}
                alt="vizyonumuz"
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-6">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-11">
                  <h6 className="h1 mb-3">Vizyonumuz</h6>
                  <p6 className="lead fs-5 text-secondary mb-3">
                    Klas Emlak olarak vizyonumuz, gayrimenkul yatırım
                    danışmanlığı alanında müşterilerimize hayallerindeki yaşam
                    alanını bulmalarında rehberlik etmek ve yenilikçi
                    çözümlerle, güvenilir bir emlak deneyimi sunarak sektörde
                    öncü olmayı hedefliyoruz. Kaliteli hizmet anlayışımızla,
                    müşteri memnuniyetini en üst düzeyde tutarak, güvenilir bir
                    emlak platformu için çalışıyoruz.
                  </p6>
                  <br />
                  <br />
                  <br />
                  <div className="row gy-4 gy-md-0 gx-xxl-5X mb-4">
                    <div className="col-12 col-md-6">
                      <div className="d-flex about-flex">
                        <div className="me-4 text-primary">
                          <i
                            class="fa-solid fa-eye fa-2xl"
                            style={{ color: "#50A596" }}
                          ></i>
                        </div>
                        <div className="icon-container">
                          <h6 className="mb-3">Geleceğe Bakıyoruz</h6>
                          <p6 className="text-secondary mb-0">
                            Geleceği düşünerek, şimdiden en uygun yatırım
                            fırsatlarını sunuyoruz.
                          </p6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="d-flex about-flex">
                        <div className="me-4 text-primary">
                          <i
                            class="fa-regular fa-face-smile-beam fa-2x"
                            style={{ color: "#50A596" }}
                          ></i>
                        </div>
                        <div className="icon-container">
                          <h6 className="mb-3">
                            Müşteri Memnuniyetini Önemsiyoruz
                          </h6>
                          <p6 className="text-secondary mb-0">
                            Hem alıcılarımızı hem de satıcılarımızı memnun etmek
                            için özenle çalışıyoruz.
                          </p6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="py-3 py-md-5 py-xl-8"
        style={{ width: "85%", margin: "0 auto" }}
      >
        <div className="container-fluid mx-md-auto">
          <div className="row gy-md-4 gy-lg-0 align-items-lg-center ">
            <div className="col-12 col-lg-8 col-xl-6 p-0">
              <img
                className="img-fluid"
                loading="lazy"
                src={about3}
                alt="misyonumuz"
              />
            </div>
            <div className="col-12 col-lg-4 col-xl-6">
              <div className="row justify-content-xl-center">
                <div className="col-12 col-xl-11">
                  <h6 className="h1 mb-3">Misyonumuz</h6>
                  <p6 className="lead fs-5 text-secondary mb-3">
                    Klas Emlak olarak misyonumuz, dünden bugüne yatırım değeri
                    sunan gayrimenkul projelerini hayata geçirerek müşterilerine
                    sürdürülebilir ve alıcı ile satıcı haklarını koruyarak tüm
                    gayrimenkul işlemlerini güvenilir şekilde sonuçlandırmaktır.
                  </p6>
                  <br />
                  <br />
                  <br />
                  <div className="row gy-4 gy-md-0 gx-xxl-5X mb-4">
                    <div className="col-12 col-md-6">
                      <div className="d-flex about-flex">
                        <div className="me-4 text-primary">
                          <i
                            class="fa-solid fa-bullseye fa-2xl"
                            style={{ color: "#50A596" }}
                          ></i>
                        </div>
                        <div className="icon-container">
                          <h6 className="mb-3">
                            Hedefe Doğru Adımlarla İlerliyoruz
                          </h6>
                          <p6 className="text-secondary mb-0">
                            Sürdürülebilir, yenilikçi ve araştırmaya dayalı
                            projelerle hedeflerimize ulaşıyoruz.
                          </p6>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="d-flex about-flex">
                        <div className="me-4 text-primary">
                          <i
                            class="fa-solid fa-shield fa-2x"
                            style={{ color: "#50A596" }}
                          ></i>
                        </div>
                        <div className="icon-container">
                          <h6 className="mb-3">
                            Güvenilirliğimizi Her Zaman Koruyoruz
                          </h6>
                          <p6 className="text-secondary mb-0">
                            Taraflar arasında güvenilir bir köprü oluşturarak ve
                            yasal hakları gözeterek işlemleri
                            gerçekleştiriyoruz.
                          </p6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
