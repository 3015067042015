import React, { useEffect, useState } from "react";
import "./Ref.css";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Listing() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [props, setProps] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const getRef = async () => {
      try {
        const res = await axios.get(`${API_URL}prop`);
        setProps(res.data);
        setImages(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getRef();
  }, []);

  // console.log(setImages);
  return (
    <div className="listing">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h3 style={{ textAlign: "center", fontSize: "45px", color: "white" }}>
        Referanslarımız
      </h3>
      <div id="propertieslisting">
        <div className="container propContainer">
          {props.map((prop, index) => (
            <>
              {prop.isSoldOut === true && (
                <div key={index} className="cards propcards">
                  <section className="propcard">
                    <figure>
                      <div className="img-overlay hot-home">
                        {images && (
                          <>
                            <img
                              src={prop.imageUrls[0]}
                              alt={prop.description}
                            />
                            <div className="overlay">
                              <Link to={`/refdetails/${prop._id}`}>
                                Detayına Git
                              </Link>
                            </div>
                          </>
                        )}
                      </div>
                      <figcaption>{prop.title}</figcaption>
                    </figure>
                  </section>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
