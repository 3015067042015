import React from "react";
import "../Team/Team.css";

function Informiki() {
  return (
    <div className="team">
      <div className="teamwrapper">
        <section>
          <div className="row" style={{ marginTop: "150px" }}>
            <h1>
              <b>GİZLİLİK POLİTİKASI</b>
            </h1>
          </div>
          <div className="row">
            <div className="column2" style={{ margin: "0 auto" }}>
              <div className="card2">
                <ul>
                  <li>
                    Klas Emlak olarak, müşterilerimizin ve ziyaretçilerimizin
                    gizliliğini önemsiyoruz. Bu gizlilik politikası, sitemizi
                    ziyaret edenlerin kişisel bilgilerinin nasıl toplandığını,
                    kullanıldığını, korunduğunu ve paylaşıldığını
                    açıklamaktadır. Lütfen bu politikayı dikkatlice okuyun.
                  </li>
                </ul>
                <br />
                <br />

                <h2>Toplanan Bilgiler </h2>
                <ul>
                  <li>
                    Ziyaretçi Bilgileri: Sitemizi ziyaret ettiğinizde, tarayıcı
                    bilgileriniz, IP adresiniz ve ziyaret ettiğiniz sayfalar
                    gibi otomatik olarak toplanan bazı bilgileri alabiliriz.
                  </li>
                  <li>
                    Kişisel Bilgiler: Site üzerinden bize ilettiğiniz ad,
                    e-posta adresi, telefon numarası gibi kişisel bilgiler.
                  </li>
                </ul>
                <br />
                <br />

                <h2>Kullanım Amaçları </h2>
                <ul>
                  <li>
                    Hizmet Sunumu: Kişisel bilgilerinizi, taleplerinizi işlemek
                    ve size daha iyi hizmet sunabilmek için kullanabiliriz.
                  </li>
                  <li>
                    İletişim: Size sitemizle ilgili güncellemeler, kampanyalar
                    veya benzeri bilgileri iletmek için kullanabiliriz.
                  </li>
                  <li>
                    Analiz ve İyileştirme: Sitemizin kullanımını analiz ederek,
                    hizmetlerimizi ve içeriğimizi geliştirmek için
                    kullanabiliriz.
                  </li>
                </ul>
                <br />
                <br />

                <h2>Bilgi Güvenliği</h2>
                <ul>
                  <li>
                    Müşteri bilgilerinin güvenliği bizim için önemlidir. Ancak,
                    internet üzerinden iletişim her zaman tamamen güvenli
                    olmayabilir. Bilgilerinizin kaybını, kötüye kullanımını veya
                    değiştirilmesini önlemek için gerekli tüm önlemleri
                    alıyoruz.
                  </li>
                </ul>
                <br />
                <br />

                <h2>Üçüncü Taraf Hizmetleri </h2>
                <ul>
                  <li>
                    Sitemizde üçüncü taraf sitelere bağlantılar veya
                    entegrasyonlar bulunabilir. Bu sitelerin gizlilik
                    politikalarını kontrol ettiğinizden emin olun, çünkü biz bu
                    sitelerin içeriği veya uygulamaları üzerinde kontrol sahibi
                    değiliz.
                  </li>
                </ul>
                <br />
                <br />

                <h2>İletişim </h2>
                <ul>
                  <li>
                    Gizlilik politikamızla ilgili sorularınız varsa, lütfen
                    bizimle iletişime geçin.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Informiki;
