import React, { useState } from "react";
import "./Faq.css";

function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleQuestionClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <div className="faq">
        <div className="faqwrapper">
          <h1>Sıkça Sorulan Sorular</h1>
          {faqData.map((item, index) => (
            <div className="faqcontainer" key={index}>
              <div
                className={`faqquestion ${
                  index === activeIndex ? "active" : ""
                }`}
                onClick={() => handleQuestionClick(index)}
              >
                {item.question}
              </div>
              <div
                className="faqanswercont"
                style={{
                  maxHeight: index === activeIndex ? "1000px" : "0",
                  overflow: "hidden",
                  transition: "max-height 0.4s ease",
                }}
              >
                <div className="faqanswer">{item.answer}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

const faqData = [
  {
    question: "Emlak ilanlarınızın güncellik durumu nedir?",
    answer:
      "Emlak ilanlarımız düzenli olarak güncellenmektedir. Ancak, en güncel bilgileri almak için lütfen bize ulaşabilirsiniz",
  },
  {
    question: "Emlak ilanlarınızda hangi tür özellikleri bulabilirim?",
    answer:
      "Emlak ilanlarımız arasında konut tipi, oda sayısı, metrekare, fiyat ve diğer önemli özellikler bulunmaktadır. Daha detaylı bilgi için lütfen bize ulaşın.",
  },
  {
    question:
      "Emlak ilanlarınızda kiralık ve satılık seçenekleri bulunuyor mu?",
    answer:
      "Evet, emlak ilanlarımız arasında kiralık ve satılık seçenekleri bulunmaktadır. İhtiyacınıza uygun olanı seçerek ilanları inceleyebilirsiniz.",
  },
  {
    question:
      "Emlak ilanlarını inceledikten sonra görüşme yapmak için ne yapmalıyım?",
    answer:
      "Sitemizde bulunan iletişim bilgilerini kullanarak doğrudan bizimle iletişime geçebilir ve görüşme ayarlayabilirsiniz.",
  },
  {
    question: "Emlak ilanlarınızda hangi tür konutları bulabilirim?",
    answer:
      "Platformumuzda arsalar, villalar, evler ve ticari alanlar gibi çeşitli gayrimenkul türlerini bulabilirsiniz.",
  },
];

export default Faq;
