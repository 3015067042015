import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./RefDetails.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { useLocation } from "react-router";
import axios from "axios";

const RefDetails = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [props, setProps] = useState({});
  const [images, setImages] = useState([]);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const getPropsById = async () => {
      try {
        const res = await axios.get(`${API_URL}prop/${id}`);
        setProps(res.data);
        setImages(res.data.imageUrls);
      } catch (error) {
        console.log(error);
      }
    };
    getPropsById();
  }, [id]);
  console.log(id);
  return (
    <div id="refdetail">
      <div className="container refcontainer-detail ">
        <div className="refdetails d-flex">
          <figcaption className="detail-top-title">{props.title}</figcaption>
        </div>
        <div id="refslider">
          <div className=" rowslider">
            <div className="slidercont">
              <div className="refslider ">
                <>
                  <Swiper
                    style={{ "--swiper-navigation-color": "#fff" }}
                    spaceBetween={10}
                    navigation={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    thumbs={{
                      swiper:
                        thumbsSwiper && !thumbsSwiper.destroyed
                          ? thumbsSwiper
                          : null,
                    }}
                    className="refproduct-images-slider2"
                  >
                    {images && (
                      <>
                        {images.map((image, index) => (
                          <SwiperSlide key={index}>
                            <img
                              src={image}
                              alt={`Slider Image ${image.value}`}
                            />
                          </SwiperSlide>
                        ))}
                      </>
                    )}
                  </Swiper>
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    onSwiper={setThumbsSwiper}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="refproduct-images-slider"
                  >
                    {images && (
                      <>
                        {images.map((image, index) => (
                          <SwiperSlide key={index}>
                            <img
                              src={image}
                              alt={`Slider Image ${image.value}`}
                            />
                          </SwiperSlide>
                        ))}
                      </>
                    )}
                  </Swiper>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefDetails;
