import React, { useEffect, useState } from "react";
import "./Properties.css";
import { Link } from "react-router-dom";
import axios from "axios";

function Properties() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [props, setProps] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}prop`)
      .then((res) => {
        setProps(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div id="propertiesxxx">
      {props.map((prop) => (
        <>
          {prop.isFeatured == true && (
            <div className="container">
              <div className="cards">
                <section className="card">
                  <figure>
                    <div className="img-overlay hot-home">
                      <img src={prop.imageUrls[0]} alt={prop.description} />
                      <div className="overlay">
                        <Link to={`/details/${prop._id}`}>İlana Git</Link>
                      </div>
                    </div>
                    <figcaption>{prop.title}</figcaption>
                  </figure>
                  <div className="card-content">
                    <p>{prop.description}</p>
                    <section className="icons-home">
                      {prop.category === "Konut" ? (
                        <>
                          <div className="name-icon">
                            <span>Oda Sayısı</span>
                            <div className="icon">
                              <i className="fas fa-bed" />
                              <span>{prop.numberofRoom}</span>
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="name-icon">
                        <span>İlçe</span>
                        <div className="icon">
                          <i class="fa-solid fa-map-pin"></i>
                          <span>{prop.ilceCountry}</span>
                        </div>
                      </div>
                      <div className="name-icon">
                        <span>Metrekare</span>
                        <div className="icon">
                          <i className="fas fa-vector-square" />
                          <span>{prop.msquare}</span>
                        </div>
                      </div>
                    </section>
                    <section className="price">
                      <span>{prop.type}</span>
                      <br />
                      <span>Fiyat için iletişime geçiniz...</span>
                    </section>
                  </div>
                </section>
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  );
}

export default Properties;
