// src/components/Main/Main.jsx
import React, { useEffect, useState } from "react";
import SearchBar from "../SearchBar/SearchBar";
import Handwrite from "../Handwrite/Handwrite";
import Properties from "../Properties/Properties";
import House from "../House/House";
import { storage } from "../../config/firebase";
import "./Main.css";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Guide from "../Guide/Guide";
import klasemlakvideomp4 from "../Main/klasemlakvideomp4.gif";
import Embedvideo from "../Embedvideo/Embedvideo";

const Main = () => {
  const [searchParams, setSearchParams] = useState({});

  const handleSearch = (params) => {
    setSearchParams(params);
  };
  const [heroImageUrl, setHeroImageUrl] = useState("");

  useEffect(() => {
    const getHeroImageUrl = async () => {
      try {
        const storageRef = ref(storage, "klas.GIF");
        const url = await getDownloadURL(storageRef);
        setHeroImageUrl(url);
      } catch (error) {
        console.error("Error getting image URL:", error);
      }
    };

    getHeroImageUrl();
  }, []);

  return (
    <div>
      <div id="header-hero-container">
        <header></header>

        <section
          id="hero"
          style={{
            backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/klasemlak-763ae.appspot.com/o/klas.GIF?alt=media&token=e1c38a28-0d57-41fe-adf6-1dcd4ff5e42a)`,
            backgroundSize: "cover",
          }}
        >
          {/* <section id="hero"> */}
          <div className="fade"></div>
          <div className="hero-text">
            <Handwrite />
            <br />
            <br />
            <div className="hero-text1">
              <h3>Yüzlerce İlan Arasından Aradığın Gayrimenkulu Bul</h3>
            </div>
          </div>
          <SearchBar onSearch={handleSearch} />
        </section>
      </div>
      <br />
      <br />
      <br />
      <h2 className="on">ÖNE ÇIKAN İLANLAR</h2>

      <Properties />
      <House />
      <Embedvideo />
      <Guide />
    </div>
  );
};

export default Main;
