import React, { useEffect, useState } from "react";
import "./House.css";
import axios from "axios";

function House() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [props, setProps] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}prop`)
      .then((res) => {
        setProps(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div className="solution sec-4-bg-img">
        <div className="inner-solution">
          <br />
          <h2 style={{ textAlign: "center" }} className="sec-4-heading">
            REFERANSLAR
          </h2>
          <div className="sec-4-grid-row">
            {props.map((prop) => (
              <>
                {prop.refVisible === true && (
                  <div className="sec-4-grid-box">
                    <img src={prop.imageUrls[0]} alt={prop.description} />
                    <h4>{prop.title}</h4>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default House;
