import React, { useEffect, useState } from "react";
import "./Listing.css";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

export default function Listing() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [props, setProps] = useState([]);
  const [sehir, setSehir] = useState("");
  const [emlaktipi, setEmlaktipi] = useState("");
  const [kategori, setKategori] = useState("");
  // const [minFiyat, setMinFiyat] = useState("");
  // const [maxFiyat, setMaxFiyat] = useState("");
  const [sehir2, setSehir2] = useState("");

  const location = useLocation();
  const handleTemizle = () => {
    setSehir("");
    setKategori("");
    setEmlaktipi("");
    // setMinFiyat("");
    // setMaxFiyat("");
    setSehir2("");
    window.location.reload();
  };

  const handleArama = (e) => {
    e.preventDefault();

    axios
      .get(`${API_URL}prop`, {
        params: {
          sehir,
          sehir2,
          kategori,
          emlaktipi,
          // minFiyat,
          // maxFiyat,
        },
      })
      .then((res) => {
        setProps(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sehirParam = queryParams.get("sehir");
    const sehir2Param = queryParams.get("sehir2");
    const kategoriParam = queryParams.get("kategori");
    const emlaktipiParam = queryParams.get("emlaktipi");
    // const minFiyatParam = queryParams.get("minFiyat");
    // const maxFiyatParam = queryParams.get("maxFiyat");

    setSehir(sehirParam || "");
    setSehir2(sehir2Param || "");
    setKategori(kategoriParam || "");
    setEmlaktipi(emlaktipiParam || "");
    // setMinFiyat(minFiyatParam || "");
    // setMaxFiyat(maxFiyatParam || "");

    axios
      .get(`${API_URL}prop`, {
        params: {
          sehir: sehirParam,
          sehir2: sehir2Param,
          kategori: kategoriParam,
          emlaktipi: emlaktipiParam,
          // minFiyat: minFiyatParam,
          // maxFiyat: maxFiyatParam,
        },
      })
      .then((res) => {
        setProps(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [location.search]);

  return (
    <div className="listing">
      <div className="listingcontainer">
        <form onSubmit={handleArama}>
          <input
            type="text"
            placeholder="Şehir"
            value={sehir}
            onChange={(e) => setSehir(e.target.value)}
          />
          <select
            name="kategori"
            value={kategori}
            onChange={(e) => setKategori(e.target.value)}
          >
            <option selected value="">
              Kategori
            </option>
            <option value="Arsa">Arsa</option>
            <option value="Arazi">Arazi</option>
            <option value="Konut">Konut</option>
            <option value="Fabrika">Fabrika</option>
            <option value="Ticari">Ticari</option>
          </select>
          <select
            name="emlaktipi"
            value={emlaktipi}
            onChange={(e) => setEmlaktipi(e.target.value)}
          >
            <option selected value="">
              Emlak Tipi
            </option>
            <option value="Satılık">Satılık</option>
            <option value="Kiralık">Kiralık</option>
          </select>
          {/* <div className="price">
            <span>₺</span>
            <input
              type="number"
              placeholder="Min Fiyat"
              value={minFiyat}
              onChange={(e) => setMinFiyat(e.target.value)}
            />
          </div> */}
          {/* <div className="price">
            <span>₺</span>
            <input
              type="number"
              placeholder="Max Fiyat"
              value={maxFiyat}
              onChange={(e) => setMaxFiyat(e.target.value)}
            />
          </div> */}
          <div id="btn_container">
            <button type="submit">Ara</button>
            <button type="button" onClick={handleTemizle}>
              Temizle
            </button>
          </div>
        </form>
        <div className="content">
          <h3>Hayalinizdekini arayın</h3>
          <p></p>
        </div>
      </div>
      <div id="propertieslisting">
        <div className="container propContainer">
          {props.map((prop, index) => (
            <>
              {prop.isSoldOut === false && (
                <div key={index} className="cards propcards">
                  <section className="card">
                    <figure>
                      <div className="img-overlay hot-home">
                        <img src={prop.imageUrls[0]} alt={prop.description} />
                        <div className="overlay">
                          <Link to={`/details/${prop._id}`}>İlana Git</Link>
                        </div>
                      </div>
                      <figcaption>{prop.title}</figcaption>
                    </figure>
                    <div className="card-content">
                      <p>{prop.description}</p>
                      <section className="icons-home">
                        {prop.category === "Konut" ? (
                          <>
                            <div className="name-icon">
                              <span>Oda Sayısı</span>
                              <div className="icon">
                                <i className="fas fa-bed" />
                                <span>{prop.numberofRoom}</span>
                              </div>
                            </div>
                          </>
                        ) : null}
                        <div className="name-icon">
                          <span>İlçe</span>
                          <div className="icon">
                            <i class="fa-solid fa-map-pin"></i>
                            <span>{prop.ilceCountry}</span>
                          </div>
                        </div>
                        <div className="name-icon">
                          <span>Metrekare</span>
                          <div className="icon">
                            <i className="fas fa-vector-square" />
                            <span>{prop.msquare}</span>
                          </div>
                        </div>
                      </section>
                      <section className="price">
                        <span>
                          {prop.type} / {prop.category}
                        </span>
                        <br />
                        <span>Fiyat için iletişime geçiniz...</span>
                      </section>
                    </div>
                  </section>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
