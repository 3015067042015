import React from "react";
import "./Team.css";
import profil from "./profil.jpg";

function Team() {
  return (
    <div className="team">
      <div className="teamwrapper">
        <section>
          <div className="row titleFamily">
            <h1>KLAS EMLAK AİLESİ</h1>
          </div>
          <div className="row">
            <div className="column" style={{ margin: "0 auto" }}>
              <div className="card">
                <div className="img-container">
                  <img src={profil} />
                </div>
                <h3>BURHAN GÜLAN</h3>
                <p>KURUCU</p>
                <div className="icons">
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="#">
                    <i className="fas fa-yin-yang" />
                  </a>
                  <a href="#">
                    <i className="fab fa-github" />
                  </a>
                  <a href="#">
                    <i className="fas fa-envelope" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="teamwrapper">
        <section>
          <div className="row">
            {/* Column 1*/}
            <div className="column">
              <div className="card">
                <div className="img-container">
                  <img src={profil} />
                </div>
                <h3>EMRE GÜLAN</h3>
                <p>GAYRİMENKUL DANIŞMANI</p>
                <div className="icons">
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="#">
                    <i className="fas fa-yin-yang" />
                  </a>
                  <a href="#">
                    <i className="fab fa-github" />
                  </a>
                  <a href="#">
                    <i className="fas fa-envelope" />
                  </a>
                </div>
              </div>
            </div>
            {/* Column 2*/}
            <div className="column">
              <div className="card">
                <div className="img-container">
                  <img src={profil} />
                </div>
                <h3>ATAKAN GÜLAN</h3>
                <p>GAYRİMENKUL DANIŞMANI</p>
                <div className="icons">
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin" />
                  </a>
                  <a href="#">
                    <i className="fab fa-github" />
                  </a>
                  <a href="#">
                    <i className="fas fa-envelope" />
                  </a>
                </div>
              </div>
            </div>
            {/* Column 3*/}
            <div className="column">
              <div className="card">
                <div className="img-container">
                  <img src={profil} />
                </div>
                <h3>MUHAMMED MERCAN</h3>
                <p>GAYRİMENKUL DANIŞMANI </p>
                <div className="icons">
                  <a href="#">
                    <i className="fab fa-twitter" />
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin" />
                  </a>
                  <a href="#">
                    <i className="fab fa-github" />
                  </a>
                  <a href="#">
                    <i className="fas fa-envelope" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Team;
