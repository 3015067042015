import React from 'react';
import './Guide.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


function Guide() {
  //test
  return (
    <div>
        <div style={{textAlign:"center"}}>
            <h3 className='sec-4-heading'>NEDEN BİZ?</h3>
        </div><br /> <br />
        <section id="alt-services" className="alt-services">
  <div className="container" data-aos="fade-up">
    <div className="row justify-content-around gy-4">
    <div className="col-lg-6 img-bg" data-aos="zoom-in" data-aos-delay={100}></div>
    

      <div className="col-lg-5 d-flex flex-column justify-content-center">
        <h3>KLAS EMLAK</h3>
        <p>Emlak sektöründeki geniş ve çeşitli portföyümüz sayesinde, müşterilerimize farklı ihtiyaçlarına uygun birçok seçenek sunabiliyoruz. Satılık veya kiralık konutlar, ticari mülkler ve yatırım amaçlı emlaklar gibi birçok kategoride geniş bir yelpaze sunuyoruz.</p>
        <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay={100}>
          <i className="bi bi-easel flex-shrink-0" />
          <div>
            <h4><a href className="stretched-link">Deneyimli ve Uzman Ekip</a></h4>
            <p>"Biz", uzman bir ekibe sahip olduğumuzu ve müşterilere profesyonel bir hizmet sunarak onların beklentilerini aştığımızı belirtebiliriz.</p>
          </div>
        </div>{/* End Icon Box */}
        <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay={200}>
          <i className="bi bi-patch-check flex-shrink-0" />
          <div>
            <h4><a href className="stretched-link">Müşteri Memnuniyeti</a></h4>
            <p>"Biz", müşteri memnuniyetini odak noktamız olarak belirterek, geri bildirimlere önem verdiğimizi ve müşterilerimizin memnuniyetini sağlama konusunda kararlı olduğumuzu ifade edebiliriz.</p>
          </div>
        </div>{/* End Icon Box */}
        <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay={300}>
          <i className="bi bi-brightness-high flex-shrink-0" />
          <div>
            <h4><a href className="stretched-link">Hizmet ve Danışmanlık</a></h4>
            <p> "Biz", müşterilere özel hizmet ve danışmanlık sağladığımızı belirterek, onların ihtiyaçlarına uygun çözümler sunma konusundaki taahhüdümüzü ifade edebiliriz.</p>
          </div>
        </div>{/* End Icon Box */}
        <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay={400}>
          <i className="bi bi-brightness-high flex-shrink-0" />
          <div>
            <h4><a href className="stretched-link">Güvenilir ve Hızlı İşlem Süreçleri</a></h4>
            <p>Emlak işlemlerinde güvenilirlik ve hız önemlidir. Müşterilerimizin işlemlerini en güvenilir ve hızlı şekilde yönetmeye özen gösteriyoruz. </p>
          </div>
        </div>{/* End Icon Box */}
      </div>
    </div>
  </div>
</section><br /><br /><br /><br /><br />

    </div>
  )
}

export default Guide