import React from "react";

function Embedvideo() {
  return (
    <div className="">
      <div style={{ textAlign: "center" }}>
        <h3 className="sec-4-heading">HİZMET VERDİĞİMİZ BÖLGELER</h3>
      </div>
      <br /> <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <iframe
          width="800"
          height="450"
          src="https://www.youtube.com/embed/3agUMW3Uyzo?si=KvayZIJForVW9jTF"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          autoplay
          allowfullscreen
          style={{
            maxWidth: "80%", // Videonun maksimum genişliği
            width: "80%", // Videonun genişliği
          }}
        ></iframe>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Embedvideo;
