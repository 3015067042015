// SearchBar.js

import React, { useState } from "react";
import "./SearchBar.css";
import { Link } from "react-router-dom";

function SearchBar() {
  const [sehir, setSehir] = useState("");
  const [sehir2, setSehir2] = useState("");
  const [kategori, setKategori] = useState("");
  const [emlaktipi, setEmlaktipi] = useState("");

  const handleArama = (e) => {
    // Form submit işlemi
    e.preventDefault();

    // Link component'ına yönlendirme işlemi
    window.location.href = `/listing?sehir=${sehir}&sehir2=${sehir2}&kategori=${kategori}&emlaktipi=${emlaktipi}`;
  };

  return (
    <div className="search-container">
      <div className="search-in">
        <select
          className="form-select srcin"
          aria-label="Default select example"
          value={kategori}
          onChange={(e) => setKategori(e.target.value)}
        >
          <option>Kategori</option>
          <option value="Arsa">Arsa</option>
          <option value="Arazi">Arazi</option>
          <option value="Konut">Konut</option>
          <option value="Fabrika">Fabrika</option>
          <option value="Ticari">Ticari</option>
        </select>
        <select
          className="form-select srcin"
          aria-label="Default select example"
          value={emlaktipi}
          onChange={(e) => setEmlaktipi(e.target.value)}
        >
          <option>Emlak Tipi</option>

          <option value="Satılık">Satılık</option>
          <option value="Kiralık">Kiralık</option>
        </select>
        <input
          className="srcin"
          aria-label="Default select example"
          placeholder="&nbsp;&nbsp;İl yazınız"
          value={sehir}
          onChange={(e) => setSehir(e.target.value)}
        />
        <input
          className="srcin"
          aria-label="Default select example"
          placeholder="&nbsp;&nbsp;İlçe yazınız"
          value={sehir2}
          onChange={(e) => setSehir2(e.target.value)}
        ></input>
        <Link
          to={`/listing`}
          type="button"
          className="btn hemenara"
          onClick={handleArama}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Hemen Ara
        </Link>
      </div>
    </div>
  );
}

export default SearchBar;
