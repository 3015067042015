import { useEffect, useRef } from "react";
import { FaTimes, FaBars } from "react-icons/fa";
import "./Navbar1.css";
import logo2 from "../Main/logo2.png";
import { Link } from "react-router-dom";

const Navbar1 = () => {
  // const [mobile, setMobile] = useState(false);
  const navRef = useRef();
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar");
      const scrolled = window.scrollY > 0;

      if (scrolled) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  return (
    <nav className="navbar">
      <>
        <div className="nav-container">
          <div className="logo">
            <Link to="/">
              <img src={logo2} alt="logo" />
            </Link>
          </div>
          <ul ref={navRef} className="nav-links">
            <li className="nav-item">
              <Link onClick={showNavbar} to="/">
                Anasayfa
              </Link>
            </li>
            <li className="nav-item">
              <Link onClick={showNavbar} to="/listing">
                İlanlarımız
              </Link>
            </li>
            <li className="nav-item">
              <Link onClick={showNavbar} to="/ref">
                Referanslar
              </Link>
            </li>
            <li className="nav-item">
              <Link onClick={showNavbar} to="/about">
                Hakkımızda
              </Link>
            </li>
            <li className="nav-item">
              <Link onClick={showNavbar} to="/press">
                Medya
              </Link>
            </li>
            <li className="nav-item">
              <Link onClick={showNavbar} to="/contact">
                İletişim
              </Link>
            </li>
            <button className="nav-btn nav-close-btn" onClick={showNavbar}>
              <FaTimes />
            </button>
          </ul>
          <button className="nav-btn" onClick={showNavbar}>
            <FaBars />
          </button>
        </div>
      </>
    </nav>
  );
};

export default Navbar1;
